import React, { useCallback, useEffect, useState } from 'react';

import { RollbackOutlined } from '@ant-design/icons';
import { Grid } from '@lp/ds-next';
import { Col, Row } from 'antd';
import { Alert, Button, Radio, RadioChangeEvent, Space } from 'antd';
import { Link as RouterLink } from 'react-router-dom';
import { useNavigate, useParams } from 'react-router-dom';

import TransactionModalAddress from './TransactionModalAddress';
import TransactionModalBankInfo from './TransactionModalBankInfo';
import useTransaction, {
  TRANSACTION_OBJECT_LABEL,
  TRANSACTION_STATE,
  TRANSACTION_STATE_LABEL,
} from '../hooks/useTransaction';
import CustomModal from '@/components/CustomModal';
import TextInput from '@/components/forms/TextInput';
import PaidIcon from '@/components/icons/PaidIcon';
import PageTitle from '@/components/layout/PageTitle';
import PillButton from '@/components/PillButton';
import { appRoutes } from '@/configs/appRoutes';
import Label from '@/shared/components/Label';
const TransactionDetail = () => {
  const navigate = useNavigate();
  const { getTransaction, updateTransaction, updateTransactionState } =
    useTransaction();
  const { transactionId } = useParams();
  const [transaction, setTransaction] = useState<any>();
  const [certificateIdExists, setCertificateIdExists] = useState<any>(false);
  const [isUpdatable, setIsUpdatable] = useState<boolean>(true);
  const [isAccepted, setIsAccepted] = useState<boolean>(false);
  const [showTransaction, setShowTransaction] = useState<boolean>(false);
  const [transactionState, setTransactionState] = useState<TRANSACTION_STATE>(
    TRANSACTION_STATE.TRANSACTION_STATE_UNSPECIFIED
  );
  const [showTransactionBankInfo, setShowTransactionBankInfo] =
    useState<boolean>(false);
  const [showTransactionBillingAddress, setShowTransactionBillingAddress] =
    useState<boolean>(false);
  const handleUpdateTransactionState = useCallback(async () => {
    const { data } = await updateTransactionState(
      transaction.id,
      transactionState
    );

    setTransaction(data);
    setShowTransaction(false);
  }, [transaction, updateTransactionState, transactionState]);
  useEffect(() => {
    if (transactionId) {
      const loadTransaction = async () => {
        const data = await getTransaction(transactionId);
        setTransaction(data);
        data.certificate_id
          ? setCertificateIdExists(true)
          : setCertificateIdExists(false);
      };
      loadTransaction();
    }
  }, [transactionId, getTransaction]);

  const handleChange = useCallback(
    (e) => {
      const { name, value } = e.target;
      setTransaction({ ...transaction, [name]: value });
    },
    [transaction]
  );

  const handleClickSave = useCallback(async () => {
    if (transactionId) {
      const { data } = await updateTransaction(transactionId, {
        certificateId: transaction.certificate_id,
        /*  certificatUrl: transaction.certificate_url, on next time*/
      });

      setTransaction(data);
      setIsUpdatable(!isUpdatable);
      if (data.certificate_id) {
        setCertificateIdExists(true);
      }
    }
  }, [transactionId, updateTransaction, transaction, isUpdatable]);

  return (
    <>
      <Grid
        container
        justifyContent="center"
        spacing={3}
        style={{ background: '#fafafa' }}
      >
        <Grid item mobile={11}>
          <PageTitle title="Transaction details" />
        </Grid>
        <Grid item mobile={11}>
          <Row justify="end" style={{ marginBottom: '10px' }}>
            <PillButton
              type="ghost"
              onClick={() => {
                navigate(appRoutes.TRANSACTION.ROOT);
              }}
            >
              <RollbackOutlined /> back
            </PillButton>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <TextInput
                name="id"
                label="Transaction ID"
                value={transaction?.id}
                disabled
              />
              <PillButton></PillButton>
              <TextInput
                name="amount"
                label="Amount"
                value={transaction?.amount}
                disabled
              />
              <TextInput
                name="transaction_object_type"
                label="Transaction type"
                disabled
                value={
                  TRANSACTION_OBJECT_LABEL[transaction?.transaction_object_type]
                }
              />
              <TextInput
                name="certificate_id"
                label="certificat ID"
                onChange={(e) => handleChange(e)}
                value={transaction?.certificate_id}
                onBlur={(e: any) =>
                  e.target.value.length > 0
                    ? setIsUpdatable(false)
                    : setIsUpdatable(true)
                }
                disabled={
                  transaction?.state ===
                    TRANSACTION_STATE.TRANSACTION_STATE_DONE ||
                  transaction?.state ===
                    TRANSACTION_STATE.TRANSACTION_STATE_FAILED
                }
              />
              <TextInput
                name="certificate_url"
                label="certificat URL"
                disabled
              />
            </Col>
            <Col span={12}>
              <Row justify="space-between" align="middle">
                <TextInput
                  name="transaction_object_id"
                  label="Contribution ID"
                  disabled
                  value={transaction?.transaction_object_id}
                  width="400px"
                />
                <PillButton>
                  <RouterLink
                    to={`/sharing-angels/contributions/${transaction?.transaction_object_id}`}
                  >
                    Go to Contribution
                  </RouterLink>
                </PillButton>
              </Row>
              <Row justify="space-around">
                <PillButton
                  onClick={() =>
                    setShowTransactionBillingAddress(
                      !showTransactionBillingAddress
                    )
                  }
                >
                  Address
                </PillButton>
                <PillButton
                  onClick={() =>
                    setShowTransactionBankInfo(!showTransactionBankInfo)
                  }
                >
                  Bank info
                </PillButton>
              </Row>
              <Row
                style={{ marginTop: '10px', paddingTop: '10px' }}
                justify="start"
                align="middle"
              >
                <TextInput
                  name="state"
                  label="Payment State"
                  disabled
                  value={TRANSACTION_STATE_LABEL[transaction?.state]}
                  width="400px"
                />
                {![
                  TRANSACTION_STATE.TRANSACTION_STATE_DONE,
                  TRANSACTION_STATE.TRANSACTION_STATE_FAILED,
                ].includes(transaction?.state) && (
                  <PillButton onClick={() => setShowTransaction(true)}>
                    <PaidIcon style={{ margin: '5px' }} /> Update transaction
                    state
                  </PillButton>
                )}
              </Row>
            </Col>
          </Row>
          <Row justify="end">
            <PillButton
              type="ghost"
              onClick={() => {
                navigate(appRoutes.TRANSACTION.ROOT);
              }}
            >
              Cancel
            </PillButton>
            <PillButton onClick={handleClickSave} disabled={isUpdatable}>
              Save
            </PillButton>
          </Row>
        </Grid>
      </Grid>

      <CustomModal
        title={<>Transaction payment state:</>}
        open={showTransaction}
        destroyOnClose={true}
        onOk={() => {
          handleUpdateTransactionState();
        }}
        okText="Save"
        okButtonProps={{
          disabled:
            transactionState ===
              TRANSACTION_STATE.TRANSACTION_STATE_UNSPECIFIED ||
            !isAccepted ||
            (transactionState === TRANSACTION_STATE.TRANSACTION_STATE_DONE &&
              !certificateIdExists),
        }}
        onCancel={() => {
          setShowTransaction(!showTransaction);
          setIsAccepted(false);
          setTransactionState(TRANSACTION_STATE.TRANSACTION_STATE_UNSPECIFIED);
        }}
      >
        <Label> Payement transaction is ? : </Label>
        <Radio.Group
          name="partner-type"
          disabled={
            transaction?.state === TRANSACTION_STATE.TRANSACTION_STATE_DONE ||
            transaction?.state === TRANSACTION_STATE.TRANSACTION_STATE_FAILED
          }
          options={[
            {
              label:
                TRANSACTION_STATE_LABEL[
                  TRANSACTION_STATE.TRANSACTION_STATE_FAILED
                ],
              value: TRANSACTION_STATE.TRANSACTION_STATE_FAILED,
            },
            {
              label:
                TRANSACTION_STATE_LABEL[
                  TRANSACTION_STATE.TRANSACTION_STATE_DONE
                ],
              value: TRANSACTION_STATE.TRANSACTION_STATE_DONE,
            },
          ]}
          onChange={({ target: { value } }: RadioChangeEvent) => {
            setTransactionState(value);
          }}
          optionType="button"
          buttonStyle="solid"
          value={transactionState}
        />

        {((transactionState === TRANSACTION_STATE.TRANSACTION_STATE_DONE &&
          certificateIdExists) ||
          transactionState === TRANSACTION_STATE.TRANSACTION_STATE_FAILED) && (
          <Alert
            style={{ marginTop: '15px' }}
            message={`You change transacton state to ${TRANSACTION_STATE_LABEL[transactionState]}. Are you sure ? Because it's irreversible`}
            type="warning"
            action={
              <Space>
                <Button key="btn-accept" onClick={() => setIsAccepted(true)}>
                  Accept
                </Button>
              </Space>
            }
          />
        )}
        {transactionState === TRANSACTION_STATE.TRANSACTION_STATE_DONE &&
          !certificateIdExists && (
            <Alert
              style={{ marginTop: '15px' }}
              message="Missing certificate id. This value is required!"
              type="warning"
            />
          )}
      </CustomModal>
      <CustomModal
        title={<>Transaction Billing address:</>}
        open={showTransactionBillingAddress}
        destroyOnClose={true}
        cancelText="Close"
        footer={null}
        onCancel={() => {
          setShowTransactionBillingAddress(!showTransactionBillingAddress);
        }}
      >
        <TransactionModalAddress address={transaction?.billing_address} />
      </CustomModal>
      <CustomModal
        title={<>Transaction Bank information:</>}
        open={showTransactionBankInfo}
        destroyOnClose={true}
        cancelText="Close"
        footer={null}
        onCancel={() => {
          setShowTransactionBankInfo(!showTransactionBankInfo);
        }}
      >
        <TransactionModalBankInfo bankInfo={transaction?.wire} />
      </CustomModal>
    </>
  );
};

export default TransactionDetail;
