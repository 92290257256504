import React, { useCallback, useEffect, useState } from 'react';

import { ReloadOutlined } from '@ant-design/icons';
import { Grid } from '@lp/ds-next';
import { DatePicker, Typography } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { parseISO } from 'date-fns';
import isArray from 'lodash/isArray';
import moment from 'moment';
import ReactJson from 'react-json-view';
import styled from 'styled-components';

import ExportBoxLogs from './ExportBoxLogs';
import {
  GPS_ACQUIRE,
  GEOLOCATION_INFO,
} from '../../shipments/hooks/useShipmentMapEvents';
import { TModifiedEvent } from '../models/telemetryEvent';
import { EVENT_TYPES } from '../models/telemetryEvent';
import PillButton from '@/components/PillButton';
import StyledTable from '@/components/tables/StyledTable';
import { getOffset, parseAntdSorter } from '@/helpers/antdTable';
import dates from '@/helpers/dates';
import useAntdColumns from '@/hooks/useAntdColumns';
import usePaginatedTableData from '@/hooks/usePaginatedTableData';
import useServerSideTable, {
  INITIAL_Pagination,
  ServerTableActions,
  ServerTableState,
} from '@/hooks/useServerSideTable';

const Wrapper = styled.div`
  margin-bottom: 1rem;
`;

const CtaWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: end;
  padding-bottom: 10px;
`;

const formatEvents = (events) => {
  if (events.length) {
    return events.map((e, i) => ({
      ...e,
      state: {
        ...e.state,
        ...((e.type === GPS_ACQUIRE || e.type === GEOLOCATION_INFO) && {
          coordinate: {
            accuracy: e?.coordinate?.accuracy,
            inAirportArea: e?.coordinate?.inAirportArea,
            latitude: e?.coordinate?.latitude,
            longitude: e?.coordinate?.longitude,
          },
        }),
      },
      consumedAt: e.consumedAt
        ? dates.formatDate(parseISO(e.consumedAt))
        : '--',
      id: i,
      parsedTimestamp: e.timestamp ? dates.parseDate(e.timestamp) : '--',
    }));
  }
};

const formatJson = (data) => (
  <Wrapper>
    <ReactJson name="state" src={data.state} />
  </Wrapper>
);

type TState = ServerTableState<TModifiedEvent[]> & {
  filter: {
    type: typeof EVENT_TYPES;
    from: number;
    to: number;
    consumedFrom: number;
    consumedTo: number;
  };
};

const reducer = (state: TState, action: ServerTableActions) => {
  switch (action.type) {
    case 'updatePagination':
      return { ...state, pagination: { ...state.pagination, ...action.args } };
    case 'updateRowDataFromResponse':
      return {
        ...state,
        rowData: formatEvents(action.args.events),
        pagination: {
          ...state.pagination,
          total: action.args.total,
          count: action.args.count,
        },
      };
    case 'updateFilters':
      return {
        ...state,
        filter: action.args.filters,
      };
    case 'updateTimeFilters':
      return {
        ...state,
        filter: { ...state.filter, ...action.args.filters },
      };
    case 'refreshTable':
      return {
        ...state,
        pagination: {
          ...state.pagination,
          pageSize:
            state.pagination.pageSize % 5 === 0
              ? state.pagination.pageSize + 1
              : state.pagination.pageSize - 1,
        },
      };
    case 'reset':
      return INIT_STATE;
    default:
      return state;
  }
};

const INIT_STATE: TState = {
  rowData: [],
  pagination: {
    ...INITIAL_Pagination,
    pageSize: 10,
    sortBy: 'consumedAt',
    order: 'DESC',
  },
  filter: {
    type: [],
    from: 0,
    to: 0,
    consumedFrom: 0,
    consumedTo: 0,
  },
};

const BoxTelemetryEvents = ({ id, isShipment = false }) => {
  const { RangePicker } = DatePicker;
  const [{ pagination, rowData, filter }, dispatch] = useServerSideTable<
    TModifiedEvent[],
    TState,
    ServerTableActions
  >(reducer, INIT_STATE);

  const [filterFrom, setFilterFrom] = useState<number>(0);
  const [filterTo, setFilterTo] = useState<number>(0);
  const [filterConsumedFrom, setFilterConsumedFrom] = useState<number>(0);
  const [filterConsumedTo, setFilterConsumedTo] = useState<number>(0);

  // Reset pagination when id changes
  useEffect(() => {
    dispatch({ type: 'reset', args: {} });
  }, [dispatch, id]);

  const { data } = usePaginatedTableData({
    params: {
      path: `/${isShipment ? 'shipments' : 'boxes'}/${id}/events`,
    },
    queryParams: {
      pageSize: pagination.pageSize,
      offset: pagination.offset,
      sortBy: pagination.sortBy,
      order: pagination.order,
      ...filter,
    },
    service: 'tracking',
  });

  useEffect(() => {
    if (!data) {
      return;
    }
    dispatch({ type: 'updateRowDataFromResponse', args: data });
  }, [data, dispatch]);

  const handleTimeRangeChange = useCallback(
    (_, dateString) => {
      let from = moment(dateString[0], 'YYYY.MM.DD').startOf('day').unix();
      let to = moment(dateString[1], 'YYYY.MM.DD').endOf('day').unix();
      if (Number.isNaN(from)) {
        from = 0;
      }
      if (Number.isNaN(to)) {
        to = 0;
      }
      setFilterFrom(from);
      setFilterTo(to);

      dispatch({
        type: 'updateTimeFilters',
        args: {
          filters: {
            from: from,
            to: to,
          },
        },
      });
    },
    [dispatch]
  );

  const handleTimeRangeChangeConsumed = useCallback(
    (_, dateString) => {
      let from = moment(dateString[0], 'YYYY.MM.DD').startOf('day').unix();
      let to = moment(dateString[1], 'YYYY.MM.DD').endOf('day').unix();
      if (Number.isNaN(from)) {
        from = 0;
      }
      if (Number.isNaN(to)) {
        to = 0;
      }
      setFilterConsumedFrom(from);
      setFilterConsumedTo(to);

      dispatch({
        type: 'updateTimeFilters',
        args: {
          filters: {
            consumedFrom: from,
            consumedTo: to,
          },
        },
      });
    },
    [dispatch]
  );

  const handleTableChange = useCallback(
    (pagination, filters, sorter) => {
      const [sortByColumn, order] = parseAntdSorter(sorter);
      const sortBy =
        sortByColumn === 'parsedTimestamp' ? 'timestamp' : sortByColumn;

      dispatch({
        type: 'updatePagination',
        args: Object.assign(
          {
            offset: getOffset(pagination.pageSize, pagination.current),
            pageSize: pagination.pageSize,
          },
          sortBy && { sortBy },
          order && { order }
        ),
      });
      dispatch({
        type: 'updateFilters',
        args: {
          filters: {
            types: isArray(filters.type) ? filters.type.join(',') : [],
            from: filterFrom,
            to: filterTo,
            consumedFrom: filterConsumedFrom,
            consumedTo: filterConsumedTo,
          },
        },
      });
    },
    [dispatch, filterConsumedFrom, filterConsumedTo, filterFrom, filterTo]
  );

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: () => (
      <RangePicker
        allowEmpty={[true, true]}
        size="small"
        onChange={
          dataIndex === 'parsedTimestamp'
            ? handleTimeRangeChange
            : handleTimeRangeChangeConsumed
        }
      />
    ),
  });

  const columns: ColumnProps<any>[] = useAntdColumns({
    columnsKeys: [
      'type',
      'parsedTimestamp',
      isShipment ? 'thingName' : 'shipmentId',
      'consumedAt',
    ],
    columnsSpecialProps: {
      type: {
        width: '20%',
        filters: EVENT_TYPES.map((eventType) => ({
          text: eventType,
          value: eventType,
        })),
        filterMultiple: true,
        render: (value: string, ...rest: any) => {
          if (value !== GEOLOCATION_INFO) {
            return value;
          }

          try {
            const [row] = rest;

            return (
              <Typography.Text
                copyable={{
                  text: `${row.state.coordinate.latitude} ${row.state.coordinate.longitude}`,
                }}
              >
                {value}
              </Typography.Text>
            );
          } catch (error) {
            return value;
          }
        },
      },
      parsedTimestamp: {
        sorter: true,
        width: '30%',
        ...getColumnSearchProps('parsedTimestamp'),
      },
      shipmentId: {
        width: '20%',
      },
      consumedAt: {
        width: '20%',
        sorter: true,
        defaultSortOrder: 'descend',
        ...getColumnSearchProps('consumedAt'),
      },
    },
  });

  const handleRefresh = useCallback(() => {
    dispatch({
      type: 'refreshTable',
      args: {},
    });
  }, [dispatch]);

  return (
    <Grid container justifyContent="center" spacing={3}>
      <Grid item mobile={12}>
        <CtaWrapper>
          <PillButton type="ghost" onClick={handleRefresh}>
            <ReloadOutlined />
          </PillButton>
        </CtaWrapper>
        <StyledTable
          rowKey="id"
          dataSource={rowData}
          columns={columns}
          pagination={{
            ...pagination,
            showSizeChanger: true,
            current: pagination.offset / pagination.pageSize + 1,
          }}
          onChange={handleTableChange}
          size="small"
          expandable={{
            expandedRowRender: (record) => formatJson(record),
          }}
        />
        <ExportBoxLogs id={id} isShipment={isShipment} />
      </Grid>
    </Grid>
  );
};

export default BoxTelemetryEvents;
