import React, { useCallback, useContext, useEffect, useState } from 'react';

import { IdcardOutlined } from '@ant-design/icons';
import { ColumnProps } from 'antd/es/table';
import { isArray } from 'lodash';
import get from 'lodash/get';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { useLifecycleStatuses } from '../../../boxes/api/lifecyle/getLifecycle';
import EditProductsModal from '../../../boxes/components/MultiEdit/EditProductsModal';
import useGetArticleCodes from '../../../boxes/hooks/useGetArticleCodes';
import useGetProductPartnerStore from '../hook/useGetProductPartner';
import useProductPartnerStore, {
  TBoxesSortBy,
} from '../store/useProductPartnerStore';
import SimStatus from '@/components/icons/SimStatus';
import StyledTable from '@/components/tables/StyledTable';
import TablesTotals from '@/components/TablesTotals';
import { scopes } from '@/config';
import {
  getCurrentPage,
  getOffset,
  parseAntdSorter,
} from '@/helpers/antdTable';
import useAntdColumns from '@/hooks/useAntdColumns';
import useAppState from '@/hooks/useAppState';
import useWindowSize from '@/hooks/useWindowSize';
import { ProductTotalContext } from '@/shared/providers/productTotalProvider';

const HolderNameWrapped = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const ProductsTable = ({
  partnerId = '',
  showModal,
  setShowModal,
  selectedRowKeys,
  setSelectedRowKeys,
}: {
  partnerId?: string;
  showModal?: boolean;
  setShowModal?: any;
  selectedRowKeys?: React.Key[];
  setSelectedRowKeys?: any;
}) => {
  useGetProductPartnerStore(partnerId);
  const { articles } = useGetArticleCodes();

  const [selectedRowIds, setSelectedRowIds] = useState<any>();
  const [isScrollTableTop, setIsScrollTableTop] = useState<boolean>(false);
  const rowData = useProductPartnerStore((state) => state.rowData);
  const loading = useProductPartnerStore((state) => state.loading);
  const pageSize = useProductPartnerStore((state) => state.pageSize);
  const offset = useProductPartnerStore((state) => state.offset);
  const total = useProductPartnerStore((state) => state.total);
  const defaultSort = useProductPartnerStore((state) => state.defaultSort);
  const dispatch = useProductPartnerStore((state) => state.dispatch);

  const { data: lifeCycleStatuses } = useLifecycleStatuses();

  const windowSize = useWindowSize();

  const pagination = {
    current: getCurrentPage(pageSize, offset),
    pageSize,
    total,
    showSizeChanger: true,
  };

  const pTotal = useContext(ProductTotalContext);

  const userScopes = useAppState((state) => state.scopes);

  const onSelectChange = useCallback(
    (newSelectedRowKeys: React.Key[]) => {
      const ids = rowData
        .filter((p) => newSelectedRowKeys.includes(p.product_id))
        .map((product) => ({
          thingName: product.lp_ui,
          id: product.product_id,
        }));

      setSelectedRowIds(ids);
      setSelectedRowKeys(newSelectedRowKeys);
    },
    [setSelectedRowKeys, setSelectedRowIds, rowData]
  );

  const handleTableChange = useCallback(
    (pagination, filters, sorter) => {
      const [sortField, sortOrder] = parseAntdSorter(sorter);
      dispatch({
        type: 'updateSorting',
        args: { sortBy: sortField as TBoxesSortBy, order: sortOrder },
      });
      dispatch({
        type: 'updatePagination',
        args: {
          offset: getOffset(pagination.pageSize, pagination.current),
          pageSize: pagination.pageSize,
        },
      });
      dispatch({
        type: 'updateFilters',
        args: {
          productLifecycleStatuses: isArray(get(filters, 'lifecycle_status'))
            ? filters.lifecycle_status
            : null,
          productDescription: isArray(get(filters, 'productDescription'))
            ? filters.productDescription
            : null,
        },
      });
    },
    [dispatch]
  );

  const rowSelection = userScopes.includes(scopes.REGISTER_BOX)
    ? {
        selectedRowKeys,
        rowData,
        onChange: onSelectChange,
      }
    : undefined;

  const columns: ColumnProps<any>[] = useAntdColumns({
    columnsKeys: [
      'lp_ui',
      'productDescription',
      'mechanical_configuration',
      'lifecycle_status',
      'contract_state',
      'contract_state_updated_at',
      ...(userScopes.includes(scopes.VIEW_LPACCOUNT) ? ['holder_name'] : []),
      /* ...(userScopes.includes(scopes.VIEW_LPACCOUNT) ? ['holder_email'] : []), */ // TODO: is missing
      /* 'shop_name', */ // Not display for the moment
      'battery_level',
      /* 'lock_state', */
      'partner_since', //  TODO: missing "Total number of shipments since it is assigned to the Partnership in api response"
      'firmware_version',
      'sim_status',
      /*'sim_status_updated_at', */ // Not display for the moment
    ],
    addDefaultColumns: false,
    columnsSpecialProps: {
      lp_ui: {
        isFull: true,
      },
      lifecycle_status: {
        filters: lifeCycleStatuses?.statuses?.map((r) => ({
          text: r,
          value: r,
        })),
        filterMultiple: true,
      },
      productDescription: {
        title: 'Article',
        filters:
          articles &&
          articles.map((article) => ({
            text: article.description,
            value: article.code,
          })),
        filterMultiple: true,
      },
      holder_name: {
        render: (text, record) =>
          record.holder_id && (
            <HolderNameWrapped>
              <span>{text}</span>
              <Link to={`/lp-account/${record.holder_id}`}>
                <IdcardOutlined width="3em" />
              </Link>
            </HolderNameWrapped>
          ),
      },
      contract_state: {
        width: '4rem',
        align: 'center',
      },
      contract_state_updated_at: {
        width: '4rem',
        align: 'center',
      },
      partner_since: {
        width: '5rem',
        align: 'center',
      },

      battery_level: {
        width: '3rem',
        align: 'center',
      },
      firmware_version: {
        width: '3rem',
        align: 'center',
      },
      sim_status: {
        align: 'center',
        width: '3rem',
        render: (_, record) => <SimStatus status={record.sim_status} />,
      },
    },
    defaultSort: defaultSort,
    eyeLinkProps: { to: '/products', queryParam: 'lp_ui', width: '2rem' },
  });

  useEffect(() => {
    if (isScrollTableTop) {
      const tableBody = document.querySelector('.ant-table-body');
      tableBody?.scrollTo({ top: 0, behavior: 'smooth' });
      setIsScrollTableTop(false);
    }
  }, [isScrollTableTop]);

  return (
    <>
      <StyledTable
        rowKey="id"
        rowSelection={rowSelection}
        dataSource={rowData}
        columns={columns}
        size="small"
        pagination={pagination}
        loading={loading}
        scroll={{ y: get(windowSize, 'height', 0) * 0.65 }}
        onChange={handleTableChange}
        footer={() => (
          <TablesTotals
            total={total}
            gTotal={pTotal}
            selectedItems={selectedRowKeys?.length}
          />
        )}
      />
      <EditProductsModal
        ids={selectedRowIds}
        showModal={showModal}
        setShowModal={setShowModal}
        setIsScrollTableTop={setIsScrollTableTop}
      />
    </>
  );
};

export default ProductsTable;
