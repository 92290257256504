import { useMutation } from '@tanstack/react-query';
import { pick } from 'lodash';

import { useSyncWithERP } from './syncWithERP';
import useError from '@/hooks/useError';
import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { IAddToPartnerAddress } from '@/models/address';
import useToasts from '@/shared/hooks/useToasts';
import { MessageType } from '@/shared/hooks/useToasts.types';

export const updatePartnerAddress =
  (makeRequest: any) =>
  async ({ values }: { values: IAddToPartnerAddress }): Promise<any> => {
    const { error, data } = await makeRequest({
      path: `/admin/addresses/${values.id}`,
      method: 'patch',
      body: pick(values, [
        'company',
        'street',
        'street2',
        'postalCode',
        'city',
        'countryCode',
        'building',
        'phoneNumber',
        'email',
        'addressTypes',
      ]),
    });

    if (error) {
      throw new Error(error);
    }

    return data;
  };

type UseUpdateAddressPartnerOptions = {
  partnerId: string;
  config?: MutationConfig<ReturnType<typeof updatePartnerAddress>>;
};

export const useUpdatePartnerAddresses = ({
  partnerId,
  config,
}: UseUpdateAddressPartnerOptions) => {
  const [, makeRequest] = useImperativeRequestWrapper('lpVillage');
  const addError = useError();
  const addSuccessMsg = useToasts((state) => state.addItem);

  const { mutate: syncWithERP } = useSyncWithERP();

  return useMutation({
    onError: () => {
      addError('Error');
    },
    onSuccess: () => {
      queryClient.refetchQueries({
        queryKey: ['partner', partnerId, 'addresses'],
      });

      addSuccessMsg(
        {
          msg: 'Address was successfully updated!',
          type: MessageType.Success,
        },
        'root',
        {}
      );

      // Sync with ERP after updating the address
      syncWithERP();
    },
    ...config,
    mutationFn: updatePartnerAddress(makeRequest),
  });
};
